export interface ToastManagerListener {
  show:(text: string) => void;
}

export class ToastManager {
  private static instance:ToastManager;
  private listener?: ToastManagerListener;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {
  }


  public static shared(): ToastManager {
    if (!ToastManager.instance) {
      ToastManager.instance = new ToastManager();
    }

    return ToastManager.instance;
  }

  registerListener(listener: ToastManagerListener): void {
    this.listener = listener;
  }

  show(text: string): void {
    this.listener?.show(text);
  }
}